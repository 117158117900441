html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: utopia-std, serif;
  font-weight: 400;
  font-style: italic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('background.jpg');
  background-position: center;
  background-repeat: none;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
