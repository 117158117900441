.App,
.App-header {
  height: 100%;
}

.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App-logo {
  width: 66.666%;
  height: auto;
}

p {
  font-size: 4vw;
}